'use client'

import { StoreTag, getStore } from '@api/getStore'
import { StoreData } from '@models'
import { FC, useState, useMemo, useEffect } from 'react'
import { isEmptyObject } from '@utils/helpers'
import { StoreDataContext } from './storeDataContext'

export const StoreDataProvider = (props: any) => {
  const [isLoading, setIsLoading] = useState(true)
  const [value, setValue] = useState({
    data: {
      attributes: {
        contact_phone: '',
        currency_format: '',
        currency_symbol: '',
        customer_support_email: '',
        redirect_on_add_to_cart: true,
        default_currency: '',
        favicon_path: null,
        watermark_img: '',
        name: '',
        seo_title: '',
        settings: {},
        orders_format: {},
        brand_theme: {},
        google_analytics_token: '',
        google_ads_token: '',
        show_payment_icons: {
          amex_icon: true,
          apple_pay_icon: true,
          discover_icon: true,
          google_pay_icon: true,
          master_card_icon: true,
          visa_icon: true,
        },
        track_inventory_levels: true,
        is_home_brand: false,
      },
      countryName: '',
      countryISO: '',
      zipRequired: true,
      statesRequired: false,
      storeTags: [] as StoreTag[],
    },
  } as unknown as StoreData)
  const context = useMemo(() => ({ ...value, isLoading }), [value, isLoading])

  useEffect(() => {
    const store = JSON.parse(localStorage.getItem('store') ?? '{}')

    if (!isEmptyObject(store)) setValue(store)
    setIsLoading(true)
    getStore(Number(process.env.NEXT_PUBLIC_CACHING_TIMEOUT || ''))
      .then(res => {
        if (!isEmptyObject(res))
          localStorage.setItem('store', JSON.stringify(res))

        setValue(res)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return <StoreDataContext.Provider value={context} {...props} />
}
